<template>
    <b-modal id="edit-company" centered :title="$t('company.edit_c')" ref="modal" title-class="font-22"
             hide-footer @shown="create">

        <form id="form" method="POST" @submit.prevent="editCompany">
            <div class="form-group mt-3 mb-0">
                <label class="pb-2"> {{$t('company.name')}} :</label>
                <b-form-input type="text" placeholder="Name" v-model="company.name"></b-form-input>
            </div>
            <div class="form-group mt-3 mb-0">
                <label class="pb-2"> {{$t('company.website')}} :</label>
                <b-form-input type="text" placeholder="Website" v-model="company.website_url"></b-form-input>
            </div>
            <div class="form-group mt-3 mb-0">
                <label class="pb-2">{{$t('company.s_desc')}} :</label>
                <b-form-textarea rows="3" placeholder="Short Description" v-model="company.short_description" style="resize: none"></b-form-textarea>
            </div>
            <div class="form-group mt-3 mb-0">
                <label class="pb-2">{{$t('company.l_desc')}} :</label>
                <b-form-textarea rows="5" placeholder="Long Description" v-model="company.long_description" style="resize: none"></b-form-textarea>
            </div>

            <input type="submit" class="btn btn-primary float-right mt-3" variant="primary" :value="$t('competition.submit')"/>

        </form>
    </b-modal>


</template>

<script>

    import helper from "@/assets/helpers/helper";
    export default {
        name: "EditCompany",
        props:['companie'],

        data() {
            return {
                company: { },
                submitted: false,
            }
        },
        methods: {
            create() {
                this.company = {...this.companie}
            },
          async  editCompany() {
              let re= await helper.postReturnData('companies/'+this.company.id, this.company)
             if(re){
                 this.$emit("refresh-company",re);
                 this.$alertify.success(" success")
             }
            },

        }
    }
</script>
