<template>
    <main>
        <div class="row m-0" v-if="is_manager">
        <button v-b-modal.add-job  class="btn btn-primary ml-auto" >{{$t('company.add')}}</button>
        <add-job v-on:new-job="loadCareers"/>
        </div>
        <div v-if="load">
        <h6 class="text-muted m-3" v-if=" careers.length == 0">{{$t('validation.no_data')}}</h6>
        <div role="tablist" class="my-2" v-if=" careers.length">
            <b-card no-body class="mb-1 " v-for="(career,index) in careers" :key="career.id">
                <b-card-header header-tag="header" role="tab" >
                    <div class="row py-auto px-2">
                        <h6 class="col-xl-9 col-sm-7  my-auto">{{career.title}}</h6>
                        <div class="my-auto text-right">
                            <b-badge class="font-size-11 mr-2 ml-auto" variant="primary">{{career.candidates_count}}</b-badge>
                            <span class="badge badge-pill ml-auto font-size-12 badge-soft-success" role="button"  v-if="is_manager && career.candidates_count"  @click="showMore(career.id)">
                             {{$t('company.more')}}</span>
                        </div>
                        <button class="btn btn-link ml-auto" type="button" v-b-toggle ="'area'+index" style="cursor: pointer"  aria-expanded="true">
                            <i class="fas fa-plus"></i>
                        </button>
                    </div>
                </b-card-header>
                <b-collapse :id="'area'+index" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <b-card-text>
                            <div class="row  my-2" v-if="!is_manager">
                            <span class="badge badge-pill ml-auto font-size-12 badge-soft-primary p-2">{{career.status}}</span>
                            </div>
                            <p>{{career.description}}</p>
                            <div class="row  my-2" v-if="is_manager">
                                <button class="btn btn-danger" @click="removeCareer(career.id,index)">{{$t('company.delete')}}</button>
                                <button class="btn btn-primary ml-auto" v-if="is_manager" @click="showEdit(career)">{{$t('company.edit')}}</button>
                            </div>
                            <div class="row my-2">
                                <button @click="showPostul(career.id)" class="btn btn-primary ml-auto " v-if="!is_manager && !career.is_applied"  >{{$t('company.postuler')}}</button>
                                <button class="btn btn-secondary ml-auto " disabled  v-if="!is_manager && career.is_applied" >{{$t('company.postuler')}}</button>

                            </div>

                        </b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>
            <edit-job :j="carrer_s" v-on:refresh-job="refresh"/>

            <job-candidates :id="selectedId" :is_vip="is_vip" />
            <apply :id="job_id" v-on:apply-success="refreshJob"/>
        </div>
        </div>
        <div v-else>
            <b-skeleton width="100%" class="mb-2" height="2.5rem"></b-skeleton>
            <b-skeleton width="100%" class="mb-2" height="2.5rem"></b-skeleton>
            <b-skeleton width="100%" class="mb-2" height="2.5rem"></b-skeleton>
            <b-skeleton width="100%" class="mb-2" height="2.5rem"></b-skeleton>
        </div>
    </main>

</template>

<script>
    import helper from "@/assets/helpers/helper";
    import EditJob from "./popups/editJob";

    import Apply from "./popups/postuler";
    import AddJob from "./popups/addJob";
    import JobCandidates from "./popups/jobCandidates";

    export default {
        name: "careers-component",
        components: {JobCandidates, AddJob, Apply, EditJob},
        props:['is_vip','is_manager'],
         data(){
            return{
                careers:[],
                carrer_s:{},
                load:false,
                selectedId:'',
                job_id:''
            }},
    created(){
      //  if(this.$store.state.company.id == this.$route.params.id) this.is_manager = true
        this.loadCareers();
    },
    methods:{
            async loadCareers(){
                if(this.is_manager) this.careers = await helper.loadData('managers/companies/'+this.$route.params.id+'/careers',1);
                else this.careers = await helper.loadData('companies/'+this.$route.params.id+'/careers',1);
                this.load = true
            },
        async removeCareer(id,index){
            let a =   await helper.deleteItem('careers/'+id);
            if(a) this.careers.splice(index,1);

        },
        showEdit(c){
             this.carrer_s = c
             this.$root.$emit('bv::show::modal', 'edit-job')

        },
        showPostul(id){
            this.job_id = id
            this.$root.$emit('bv::show::modal', 'apply')
        },
        showMore(i){
            this.selectedId = i
            this.$root.$emit('bv::show::modal', 'job-candidates')

        },
        refresh(a){
           let index =  this.careers.findIndex(element => element.id > this.carrer_s.id)
            this.careers[index] = a
        },
        refreshJob(){
            let index =  this.careers.findIndex(element => element.id == this.job_id)
            this.careers[index].is_applied = 1
            this.careers[index].status = 'pending'
        }
    }

    }
</script>

<style scoped>
    .card-header{
        padding: 0rem 1.25rem!important;
    }
</style>