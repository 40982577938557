<script>
import Post from "@/components/post";
import helper from "@/assets/helpers/helper";
import ProfileCompanyHeader from "@/components/profileCompanyHeader";
import PostInput from "@/components/postInput";
import CareersComponent from "../../components/careers-component";
import Edit from "../../components/popups/edit";

export default {
  name:'ProfilCompany',
  components: {Edit, CareersComponent, PostInput, ProfileCompanyHeader, Post},
  data() {
    return {
      title: "Profile Company",
      posts: [],
      page_posts:1,
      profil_info: { },
      isLoad:false,
      content:'',
      post_id:'',
      s_index:'',
      company_id:'',
      comment_id:'',
      type:'',


    }
  },
  created(){
    this.loadData()
  },

  methods: {
    async loadData() {
      this.profil_info = await helper.getAll('companies/' + this.$route.params.id);
      this.isLoad = true
    },
    editPost(post,post_id,company_id,comment_id,type){
      console.log(type)
      this.content = post
      this.post_id = post_id
      this.company_id = company_id
      this.comment_id = comment_id
      this.type = type
      this.$root.$emit('bv::show::modal', 'modal-edit')
    },
    refreshPost(content){
      if(this.type == 'posts' || this.type == 'companies') this.posts[this.s_index].content = content
      if(this.type == 'comments' || this.type == 'cc') {
        this.posts[this.s_index].comments.forEach(element => {
          if(element.id == this.comment_id) element.content = content
        });
      }
    },

    deleteThisPost(index){
      this.posts.splice(index,1);
    },
    refresh(post){
      console.log("new",post)
      post.new = true
      this.posts.unshift(post)
      this.$forceUpdate()
    },
    infiniteHandler($state) {
      this.$http.get('companies/'+this.$route.params.id+'/posts?page=' +this.page_posts).then(({ data }) => {
        if (data.length) {
          this.page_posts += 1;
          this.posts.push(...data);
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },
  }
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <profile-company-header :info="profil_info"></profile-company-header>
      </div>
    </div>

        <div class="row flex-sm-row-reverse">
            <div class="col-xl-5">
                <h4 class="mb-2"><i class="bx bxs-briefcase mr-2"></i>Job Offers</h4>
                <careers-component v-if="isLoad" :is_vip="profil_info.is_vip" :is_manager="profil_info.is_manager"/>
                <div v-else>
                    <b-skeleton width="100%" class="mb-2" height="2.5rem"></b-skeleton>
                    <b-skeleton width="100%" class="mb-2" height="2.5rem"></b-skeleton>
                    <b-skeleton width="100%" class="mb-2" height="2.5rem"></b-skeleton>
                    <b-skeleton width="100%" class="mb-2" height="2.5rem"></b-skeleton>
                </div>
            </div>
      <div class="col-xl-7">
              <post-input v-on:refresh="refresh" :logo="$store.state.company.logo" :companie_id="$store.state.company.id" v-if="profil_info.is_manager"></post-input>
              <!-- Posts  -->
              <div v-for="(post,index) in posts" :key="post.id">
                <post :post="post" type="c" :companie="profil_info" v-on:delete-post="deleteThisPost(index)" v-on:show-edit="editPost" v-on:show="s_index = index"></post>
              </div>
        <edit :content="content" :post_id="post_id" :type="type" :company_id="company_id" :comment_id="comment_id"  v-on:refresh="refreshPost" />

        <infinite-loading @infinite="infiniteHandler">
                  <div slot="no-more">{{$t('validation.no_more_posts')}}</div>
                  <div slot="no-results">{{$t('validation.no_posts')}}</div>
              </infinite-loading>
        </div>


        </div>
      </div>







</template>
<style scoped>
    .card-header{
        padding: 0rem 1.25rem!important;
    }

</style>
