<template>
    <div class="card">
        <div class="card-body">
            <div class="row" v-if="info.name">
                <div class="col-lg-9 col-xl-10 col-md-12 col-sm-12">
                    <div class="row mx-0 mb-3">
                        <div class="mr-2 mb-1">
                            <b-avatar class="outer"  :src="$store.state.company.logo" size="lg"  v-if="info.logo && info.is_manager" ></b-avatar>
                            <b-avatar class="outer"  :src="info.logo" size="lg"  v-if="info.logo && !info.is_manager" ></b-avatar>
                            <b-avatar v-if="!info.logo" variant="primary" size="lg" :text="(info.name).substr(0, 1)" class="align-baseline outer"></b-avatar>
                          <i class="bx bx-camera mx-2 inner text-white font-size-16" role="button" @click="show = true"></i>
                          <upload-avatar v-if="info.is_manager && show"  v-on:close="show=false"  type="company" :url="'companies/'+info.id+'/avatar'"/>
                        </div>
                       <h5 class="mb-1 text-muted my-auto" >{{info.name}}</h5>
                    </div>
                  <div class="row mx-0 d-block">
                    <a class="text-dark mb-2" :href="info.website_url" target="_blank">{{info.website_url}}</a>
                    <p class="text-muted  mt-2">{{info.long_description}}</p>
                  </div>
                </div>

                <div class="col-xl-1 col-lg-1 col-md-4 col-sm-4 mb-2 w-50 ">
                    <p class="text-muted mb-2 text-center">{{$t('profil_user.followers')}}</p>
                    <h5 class="mb-0 text-center">{{info.followers_count}}</h5>
                </div>
                <div class="col-lg-1 col-xl-1 col-md-8 col-sm-8 text-right">
                    <button class="btn btn-primary mb-2 mr-2" v-if="info.is_manager" @click="$bvModal.show('edit-company')">{{$t('company.edit')}}</button>
                    <button class="btn btn-primary mb-2"  @click="Follow">{{ (info.is_followed_by_me == 0) ? $t('profil_user.follow'):$t('profil_user.unfollow')}}</button>
                    <edit-company :companie="info" v-on:refresh-company="refresh"/>
                </div>

            </div>
            <div class="row" v-else>
                <div class="col-xl-2">
                    <div class="row">
                        <b-skeleton type="avatar" class="col-sm-2 mr-2"></b-skeleton>
                        <div class="col-sm-9">
                            <b-skeleton class="mb-2"></b-skeleton>
                            <b-skeleton class="mb-2"></b-skeleton>
                        </div>
                    </div>
                </div>
                <div class="col-xl-7">
                    <b-skeleton class="mb-2" width="95%"></b-skeleton>
                    <b-skeleton width="70%"></b-skeleton>
                </div>

                <div class="col-xl-2">
                    <p class="text-muted  mb-2">{{$t('profil_user.followers')}}</p>
                    <b-skeleton ></b-skeleton>
                </div>
                <div class="col-xl-1">
                    <b-skeleton type="button" class="my-2"></b-skeleton>
                </div>

            </div>
            <!-- end row -->
        </div>
    </div>
</template>

<script>
    import EditCompany from "./popups/editCompany";
    import UploadAvatar from "./uploadAvatar";
    import helper from "@/assets/helpers/helper";

    export default {
        name: "profileCompanyHeader",
        components: {UploadAvatar, EditCompany},
        props:['info'],
      data(){
          return{
            show:false
          }
      },
        methods:{
            refresh(data){
                this.info = data
            },
            async Follow() {
                let result =  await helper.postData('companies/'+this.info.id+'/follow');
                if(result)
                {
                    this.info.is_followed_by_me = 1 - this.info.is_followed_by_me
                    this.info.followers_count += 1
                }
            },
        }

    }
</script>

<style scoped>
    .outer {
        margin: auto;
        position: relative;
    }

    .inner {
        position: absolute;
        top: 40px;
        left: 50px;
    }

</style>