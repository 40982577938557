<template>
    <b-modal id="edit-job" centered :title="$t('company.edit_j')" ref="modal" title-class="font-22"
             hide-footer @shown="create">

        <form id="form" method="POST" @submit.prevent="editJob">
            <div class="form-group mt-3 mb-0">
                <label class="pb-2"> {{$t('company.title')}} :</label>
                <b-form-input type="text" placeholder="Title" v-model="job.title"
                              :class="{ 'is-invalid': typesubmit && $v.job.title.$error }"></b-form-input>
                <div v-if="typesubmit && $v.job.title.$error" class="invalid-feedback">
                    <span v-if="!$v.job.title.required"> {{$t('validation.required')}}</span>
                </div>


            </div>
            <div class="form-group mt-3 mb-0">
                <label class="pb-2">{{$t('company.desc')}} :</label>
                <b-form-textarea rows="5" placeholder="Description" v-model="job.description" style="resize: none"></b-form-textarea>
            </div>
            <input type="submit" class="btn btn-primary float-right mt-3" variant="primary" :value="$t('competition.submit')"/>

        </form>
    </b-modal>


</template>

<script>

    import helper from "@/assets/helpers/helper";
    import {required} from "vuelidate/lib/validators";
    export default {
        name: "EditJob",
        props:['j'],
        data() {
            return {
                job: { },
                typesubmit: false,
            }
        },
        validations: {
            job:{
                title: {required},
            },
        },
        methods: {
            create() {
                this.typesubmit = false,
                this.job = {...this.j}
            },
            async  editJob() {
                this.typesubmit = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                let re= await helper.postReturnData('careers/'+this.job.id, this.job)
                if(re){
                    this.$emit("refresh-job",re);
                    this.$refs['modal'].hide()
                }
            },

        }
    }
</script>

