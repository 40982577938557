<template>
    <b-modal id="job-candidates" centered :size="is_vip?'xl':'md'" :title="$t('company.users')" ref="modal" title-class="font-22"
             hide-footer @shown="create">
        <div v-if="is_vip">
            <div class="row mt-4" v-if="isLoadedUsers && users.length">
                <simplebar
                        style="max-height: 250px;padding-right: 8px;width:100%;padding-left: 8px">
                <table class=" tab table b-table text-center " role="table">
                    <thead>
                    <tr>
                        <th scope="col" @click="sort('full_name')" role="columnheader"  :aria-sort="(currentSort == 'full_name')?currentSortDir:none">{{$t('profil_user.settings.full_name')}}</th>
                        <th scope="col" @click="sort('email')" role="columnheader" :aria-sort="(currentSort == 'email')?currentSortDir:none">{{$t('profil_user.settings.email')}}</th>
                        <th scope="col" @click="sort('description')" role="columnheader" :aria-sort="(currentSort == 'description')?currentSortDir:none">{{$t('apply_job.motivations')}}</th>
                        <th scope="col" @click="sort('status')" role="columnheader" :aria-sort="(currentSort == 'status')?currentSortDir:none">{{$t('apply_job.status')}}</th>
                        <th scope="col">{{$t('apply_job.link_cv')}}</th>

                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(data,index) of users_sorted" :key="data.id">
                        <td :data-label="$t('profil_user.settings.full_name')" class="link"  @click="$router.push({name:'ProfilUser',params:{id:data.user_id}})">{{data.full_name}}</td>
                        <td :data-label="$t('profil_user.settings.email')"  >{{data.email}}</td>
                        <td :data-label="$t('apply_job.motivations')"> {{data.description}}</td>
                        <td :data-label="$t('apply_job.status')">
                            <b-form-select v-model="data.status" :options="status" v-on:change="changeStatus(index)">
                            </b-form-select>
                        </td>
                        <td :data-label="$t('apply_job.link_cv')">
                            <a :href="data.link_cv" target="_blank"><i class="bx bx-download text-white font-size-20"></i></a></td>

                    </tr>


                    </tbody>
                </table>
                </simplebar>
            </div>
            <div v-if="!isLoadedUsers">
                <b-skeleton-table :rows="5" :columns="5"></b-skeleton-table>
                <b-skeleton></b-skeleton>
            </div>

        </div>
        <div class="row" v-else>
                <div class="col-xl-9 m-auto text-center ">
                    <i class="bx bx-lock-alt text-danger fa-4x my-4"></i>
                    <h4 class="text-dark my-4">{{$t('company.vip')}}</h4>
                    <button class="btn btn-outline-danger btn-lg mb-2" @click="$router.push({ name: 'Payment' })">subscribe</button>
                </div>
        </div>
    </b-modal>
</template>

<script>
    import helper from "@/assets/helpers/helper";
    import simplebar from "simplebar-vue";
    export default {
        name: "jobCandidates",
        props:['id','is_vip'],
        components:{simplebar},
        data(){

            return{
                none:'none',
                status:['pending','approved'],
                currentSort:'full_name',
                isLoadedUsers:false,
                currentSortDir:'descending',
                users:[]

            }
        },

        computed:{

            users_sorted:function() {
                let users = this.users;
                return users.sort((a,b) => {
                    let modifier = 1;
                    if(this.currentSortDir === 'descending') modifier = -1;
                    if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                });
            }
        },
        methods: {
            create(){
              if(this.is_vip)  this.loadUsers()
            },
            async loadUsers() {
                this.users = await helper.getAll('companies/'+this.$route.params.id+'/careers/'+this.id+'/candidates');
                this.isLoadedUsers = true
            },
            sort: function (s) {
                //if s == current sort, reverse
                if (s === this.currentSort) {
                    this.currentSortDir = this.currentSortDir === 'ascending' ? 'descending' : 'ascending';
                }
                this.currentSort = s;
            },
           async changeStatus(index) {

              await helper.postReturnData('careers/'+this.id, {'status':this.users[index].status})

            },


        }

    }
</script>

<style scoped>

</style>